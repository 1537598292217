window.lbbCareer = {
	elems: [],
	filter: {
		active: if window.localStorage.getItem('active-filters')? then if window.localStorage.getItem('active-filters') != null then JSON.parse(window.localStorage.getItem('active-filters')) else [] else [],
		groups: [
			["profession"],
			["type"],
			["classification"],
			["workingtime"],
			["temporary"],
			["area"]
		]
	},
	hideLoadMore: true,
	hashedIcon: '',
	ids: [],
	lastPageIndex: if window.localStorage.getItem('last-page-index') then if window.localStorage.getItem('last-page-index') != null then parseInt(window.localStorage.getItem('last-page-index'),10) else null else null,
	lastPageFilterRestore: false,
	lastSearchParam: "",
	localStorageOffersUpdateTime: if localStorageOffersUpdateTime? then (localStorageOffersUpdateTime * 60 * 1000) else 7200000,
	matchingOffers: [],
	newestOfferDate: if window.localStorage.getItem('newest-offer-date')? then parseInt(window.localStorage.getItem('newest-offer-date'), 10) else 0,
	offers: undefined,
	offersPages: 0,
	redoExtendedSearch: false,
	searchBarDelay: 1500,
	searchBarFirstClick: true,
	searchBarInterval: undefined,
	toTopButtonElem: undefined
}
app = window.lbbCareer


# #########
# HELPERS
###########

# Cookies
app.GetCookie = (name) ->
	name = name + "="
	cookiearray = decodeURIComponent(document.cookie).split(';')
	for cookie in cookiearray
		while cookie.charAt(0) == ' '
			cookie = cookie.substring(1)
		if cookie.indexOf(name) == 0
			return cookie.substring(name.length, cookie.length)
	return ""
app.CreateCookie = (name, value, expdays, update) ->
	if (app.GetCookie(name) != "") && !update
		return false
	else
		if expdays != null || ''
			expdate = new Date()
			expdate.setTime(expdate.getTime() + (expdays*24*60*60*1000))
			document.cookie = name + "=" + value + ";expires="+ expdate.toUTCString() + ";path=/"
		else
			document.cookie = name + "=" + value + ";path=/"
	return true
app.DeleteCookie = (name) ->
	if app.GetCookie(name) == ""
		return false
	else
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
	return true

# Copy Text
app.CopyText = (text) ->
	copyDummy = document.createElement "textarea"
	document.body.appendChild copyDummy
	copyDummy.setAttribute "id", "copy-dummy_id"
	document.getElementById("copy-dummy_id").value = text
	copyDummy.select()
	document.execCommand "copy"
	document.body.removeChild copyDummy

	return

# Cumulative Offset
app.CumulativeOffset = (elem) ->
	top = 0
	left = 0
	if elem
		while true
			top += elem.offsetTop  || 0
			left += elem.offsetLeft || 0
			elem = elem.offsetParent

			break unless elem

	return {
		top: top,
		left: left
	}

# ID Maker
app.IdMaker = (el, prefix) ->
	# if id exists, return it...
	if el.id != ""
		return el.id
	# ...otherwise create id based by hash on actual time
	else
		return prefix + (+new Date).toString(36)

app.SetId = (el, prefix) ->
	id = app.IdMaker el, prefix
	while app.ids.indexOf(id) > -1
		id = app.IdMaker el, prefix
	app.ids.push id
	el.id = id
	return id

# Has Class
app.HasClass = (elem, className) ->
	return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ')

# Scroll To Element
app.ScrollToElem = (elem, offset, notSmooth, absolute) ->
	offset = if offset then offset else 0
	elemTop = if absolute then app.CumulativeOffset(elem).top else elem.offsetTop
	window.scrollTo {
		top: elemTop + offset, 
		left: 0,
		behavior: if notSmooth then "auto" else "smooth" 
	}
	return

# Trigger Event
app.TriggerEvent = (elem, eventName) ->
	elem.dispatchEvent new Event(eventName, {'bubbles': true})
	return

# #########
# COMMON
###########

# Icon Hash

app.GetHashedIcon = ->
	if document.getElementById('header_logo')
		headerIconSvg = document.querySelector('#header_logo .icon use').getAttribute('xlink:href').match(/(icons.)[a-zA-Z0-9]*(.svg)/gi)[0]
		if headerIconSvg
			app.hashedIcon = headerIconSvg
	return

# Navigation Links Blur
app.NavLinkBlurBinder = ->
	for navLinkElem in document.querySelectorAll 'nav li a'
		navLinkElem.addEventListener 'click', (e) ->
			# blur after click, to remove accessebility based focus outline
			this.blur()
			return
	return

# Scroll Control
app.ScrollControlBinder = ->
	document.addEventListener 'scroll', (e) ->
		headerElem = document.getElementById('header')
		if window.scrollY >= window.innerHeight/2
			app.toTopButtonElem.classList.add 'show'
		else
			app.toTopButtonElem.classList.remove 'show'

		if window.scrollY >= 100
			if app.HasClass headerElem, 'fixed'
				headerElem.classList.add 'scrolled'
		else
			if app.HasClass headerElem, 'fixed'
				headerElem.classList.remove 'scrolled'

	return

# #########
# API
###########

# Check For Offers Update
app.CheckForOffersUpdate = (inquirer) ->
	storedOffers = window.localStorage.getItem('offers')
	lastOffersUpdate = if window.localStorage.getItem('last-offers-update')? then parseInt(window.localStorage.getItem('last-offers-update'), 10) else 0
	if !storedOffers || lastOffersUpdate - Date.now() <= -86400000
		app.GetAllOffers inquirer
	else
		if lastOffersUpdate - Date.now() <= -app.localStorageOffersUpdateTime
			app.GetNewestOfferDate inquirer
		else
			newestOfferDate = if window.localStorage.getItem('newest-offer-date')? then parseInt(window.localStorage.getItem('newest-offer-date'), 10) else 1
			app.offers = JSON.parse storedOffers
			if (app.offers.length < 1) || (app.newestOfferDate < newestOfferDate)
				app.newestOfferDate = newestOfferDate
				window.localStorage.removeItem('offers')
				app.GetAllOffers inquirer
			else
				if inquirer == 'search-bar-big'
					app.AppendOfferResults()
				if inquirer == 'search-bar-flyout'
					app.SearchBarRemoveProgress()
	return

# Get All Offers
app.GetAllOffers = (inquirer) ->
	orElem = document.querySelector '.offer-results'
	app.OfferResultsDeletePagination()
	if orElem
		orElem.querySelector('.offer-results_loader').classList.add 'show'
		orElem.querySelector('.offer-results_header').classList.add 'hide'
		orElem.querySelector('.offer-results_load-more').classList.add 'hide'
		if orElem.querySelector('.offer-results_pagination-control')
			orElem.querySelector('.offer-results_pagination-control').classList.add 'hide'
	offersRequest = new XMLHttpRequest()
	offersRequest.onreadystatechange = ->
		if offersRequest.readyState == 4
			if offersRequest.status == 200
				app.offers = JSON.parse offersRequest.responseText
				window.localStorage.setItem 'offers', JSON.stringify(app.offers)
				window.localStorage.setItem 'last-offers-update', Date.now()
				app.GetNewestOfferDate()
				if inquirer == 'search-bar-big'
					if document.querySelector('.advanced-select-row')
						app.AppendOfferResults()
						document.querySelector('.offer-results_loader').classList.remove 'show'
						document.querySelector('.offer-results_header').classList.remove 'hide'
						if document.querySelector('.offer-results_pagination-control')
							document.querySelector('.offer-results_pagination-control').classList.remove 'hide'
						if !app.HasClass document.querySelector('.offer-results'), 'use-pagination'
							document.querySelector('.offer-results_load-more').classList.remove 'hide'
						app.OfferResultsShowOffers document.querySelector('.offer-results')
				if inquirer == 'search-bar-flyout'
					app.SearchBarRemoveProgress()
	offersRequest.open "GET", window.location.protocol + "//" + window.location.host + "/api/offers", true
	offersRequest.send()
	return

app.GetNewestOfferDate = (inquirer) ->
	nodRequest = new XMLHttpRequest()
	nodRequest.onreadystatechange = ->
		if nodRequest.readyState == 4
			if nodRequest.status == 200
				nodResponse = JSON.parse nodRequest.responseText
				if nodResponse? && nodResponse != ""
					window.localStorage.setItem 'newest-offer-date', Date.parse(nodResponse.date)
					window.localStorage.setItem 'last-offers-update', Date.now()
					if inquirer?
						app.CheckForOffersUpdate inquirer
	nodRequest.open "GET", window.location.protocol + "//" + window.location.host + "/api/newest-offer-date", true
	nodRequest.send()
	return

# Get Matching Offers by Searchparam
app.GetMatchingOffersBySearchparam = (searchparam) ->
	app.matchingOffers = []
	matchingOffersRequest = new XMLHttpRequest()
	matchingOffersRequest.onreadystatechange = ->
		if matchingOffersRequest.readyState == 4
			if matchingOffersRequest.status == 200
				app.matchingOffers = JSON.parse matchingOffersRequest.responseText
				app.SearchBarUpdateOffersExtendedSearch()
	matchingOffersRequest.open "GET", window.location.protocol + "//" + window.location.host + "/api/search-matching?searchparam=#{ encodeURIComponent(searchparam) }", true
	matchingOffersRequest.send()
	return

# Image Copyright
class ImageCopyright
	constructor: (el) ->
		@.$el = el
		@.$copyright = undefined
		@.$imageWrapper = undefined

		@.valid = false

		@.Create()
		if @.valid
			@.InitEvents()

		@.$el.classList.add 'image-copyright--binded'

	Create: ->
		if @.$el.dataset.copyright != ""
			@.$imageWrapper = document.createElement 'div'
			@.$imageWrapper.classList.add 'image'
			@.$el.parentNode.insertBefore @.$imageWrapper, @.$el
			@.$imageWrapper.appendChild @.$el

			@.$copyright = document.createElement 'div'
			@.$copyright.classList.add 'image-copyright'
			@.$copyright.innerHTML = '<div class="image-copyright__hint">i</div><div class="image-copyright__text">' + @.$el.dataset.copyright + '</div>'
			@.$imageWrapper.appendChild @.$copyright

			@.valid = true

	InitEvents: ->
		self = @
		@.$copyright.addEventListener 'click', (e) ->
			@.classList.toggle 'image-copyright--shown'


app.ImageCopyrightBinder = ->
	document.querySelectorAll 'img[data-copyright]:not(.image-copyright--binded)'
		.forEach (el) ->
			new ImageCopyright el

# #########
# ATOMS
# #########

# Button
app.ButtonBinder = ->
	for buttonElem in document.querySelectorAll('.btn')
		buttonElem.addEventListener 'click', (e) ->
			this.blur()
			return
	return

# Filter-Tag
app.FilterTagBinder = ->
	for filterTag in document.querySelectorAll('.filter-tag')
		filterTag.addEventListener 'click', (e) ->
			e.preventDefault()
			this.classList.toggle 'active'
			return
	return

# Map
app.MapBinder = ->
	for mapElem in document.querySelectorAll '.map'
		for area in mapElem.querySelectorAll '.lbbcareer_map_areas_area'
			area.addEventListener 'click', (e) ->
				e.preventDefault()
				app.MapAreaEvent @.closest('.map'), @.dataset.area
				return
	return
app.MapAreaEvent = (elem, area) ->
	if elem
		if app.HasClass elem, 'map'
			if area.indexOf('area_') == 0
				areaElem = elem.querySelector('.lbbcareer_map_areas_area[data-area="' + area + '"]')
			if !isNaN(area)
				areaElem = elem.querySelector('.lbbcareer_map_areas_area[data-mapping="' + area + '"]')
			
			if areaElem
				if areaElem.classList.contains 'active'
					areaElem.classList.remove 'active'
				else
					areaElem.classList.add 'active'
	return

# -- Select --
app.SelectBinder = ->
	selectElemsList = document.querySelectorAll('.select')
	if selectElemsList.length > 0
		document.addEventListener 'click', (e) ->
			for selectElem in document.querySelectorAll('.select.ready-to-close')
				selectElem.classList.remove 'open'
			return
	for selectElem in selectElemsList
		selectElem.addEventListener 'click', (e) ->
			this.classList.toggle 'open'
			return
		selectElem.addEventListener 'mouseleave', (e) ->
			this.classList.add 'ready-to-close'
			return
		selectElem.addEventListener 'mouseenter', (e) ->
			this.classList.remove 'ready-to-close'
			return
		if !app.HasClass(selectElem, 'no-input')
			for selectOption in selectElem.querySelectorAll('.select_content_option')
				selectOption.addEventListener 'click', (e) ->
					this.closest('.select').classList.add 'selected'
					this.closest('.select').querySelector('.select_box_title').innerHTML = this.innerHTML
					this.closest('.select').querySelector('input[type=hidden]').value = this.dataset.option
					if app.HasClass selectElem, 'required'
						app.HideElementError selectElem
					return
	return


# Tag
app.TagBinder = ->
	for tagElem in document.querySelectorAll '.tag'
		app.TagClickBinder tagElem
	return
app.TagClickBinder = (tagElem) ->
	tagElem.querySelector('.tag_tag').addEventListener 'click', (e) ->
		e.preventDefault()
		tagElem = @.closest('.tag')
		groupElems = tagElem.parentNode.querySelectorAll('.tag[data-group="' + tagElem.dataset.group + '"]')
		if groupElems.length >= 1
			beforeText = tagElem.querySelector('.tag_text-before').textContent
			if beforeText == tagElem.dataset.prefix || beforeText == tagElem.dataset.prefixPlural
				if groupElems[1]
					groupElems[1].querySelector('.tag_text-before').innerHTML = if groupElems.length > 2 then groupElems[1].dataset.prefixPlural else groupElems[1].dataset.prefix
		tagElem.remove()
		return
	return
app.CreateTag = (tag, targetElem, textBefore = "", textAfter = "", linkedElem = "", textBeforePlural = "", group = "none") ->
	existingGroupElems = targetElem.querySelectorAll('.tag[data-group="' + group + '"]')
	if existingGroupElems.length > 0
		tagTextBefore = 'oder'
		existingGroupElems[0].querySelector('.tag_text-before').innerHTML = existingGroupElems[0].dataset.prefixPlural
	else
		tagTextBefore = textBefore
	newTagElem = document.createElement 'div'
	newTagElem.classList.add 'tag'
	newTagElem.dataset.linked = linkedElem
	newTagElem.dataset.group = group
	newTagElem.dataset.prefix = textBefore
	newTagElem.dataset.prefixPlural = textBeforePlural
	newTagElem.innerHTML = '<div class="tag_text-before">' + tagTextBefore + '</div><div class="tag_tag"><span>' + tag + '</span><div class="tag_tag_remove"></div></div><div class="tag_text-after">' + textAfter + '</div>'
	if existingGroupElems.length > 0
		existingGroupElems[existingGroupElems.length-1].after(newTagElem)
	else
		targetElem.appendChild newTagElem
	if linkedElem != ""
		newTagElem.id = 'tag_' + linkedElem

	app.TagClickBinder newTagElem
	return

# #########
# MOLECULES
# #########

# Advanced-Select
app.AdvancedSelectBinder = ->
	asElemsList = document.querySelectorAll '.advanced-select'
	if asElemsList.length > 0
		document.addEventListener 'click', (e) ->
			for asElem in document.querySelectorAll '.advanced-select.ready-to-close'
				asElem.classList.remove 'open'
				asElem.classList.remove 'ready-to-close'
			return
	for asElem in asElemsList
		asElem.addEventListener 'click', (e) ->
			if !app.HasClass this, 'open'
				this.classList.add 'open'
			return
		asElem.addEventListener 'keypress', (e) ->
			if e.code == "Space"
				this.classList.toggle 'open'
			return
		asElem.addEventListener 'blur', (e) ->
			this.classList.remove 'open'
			return
		asElem.querySelector('.advanced-select_box').addEventListener 'click', (e) ->
			e.preventDefault()
			e.stopPropagation()
			this.closest('.advanced-select').classList.toggle 'open'
			return
		asElem.addEventListener 'mouseleave', (e) ->
			this.classList.add 'ready-to-close'
			return
		asElem.addEventListener 'mouseenter', (e) ->
			this.classList.remove 'ready-to-close'
			return
		active = 0
		for checkboxElem in asElem.querySelectorAll('.checkbox')
			if checkboxElem.querySelector('input[type="checkbox"]').checked
				active++
			checkboxElem.querySelector('input[type="checkbox"]').addEventListener 'change', (e) ->
				active = parseInt this.closest('.advanced-select').dataset.choosen, 10
				if this.checked
					this.closest('.advanced-select').classList.add 'active'
					active++
					this.closest('.advanced-select').dataset.choosen = active
					this.closest('.advanced-select').querySelector('.advanced-select_choosen').innerHTML = "(" + active + ")"
				else
					active--
					this.closest('.advanced-select').dataset.choosen = active
					if active < 1
						this.closest('.advanced-select').classList.remove 'active'
						this.closest('.advanced-select').querySelector('.advanced-select_choosen').innerHTML = ""
					else
						this.closest('.advanced-select').querySelector('.advanced-select_choosen').innerHTML = "(" + active + ")"
				return
		if active > 0
			asElem.classList.add 'active'
			asElem.querySelector('.advanced-select_choosen').innerHTML = "(" + active + ")"
			asElem.dataset.choosen = active
	return
app.AdvancedSelectIncrementSpan = (name) ->
	nameElems = document.getElementsByName(name)
	if nameElems[0]
		spanElem = nameElems[0].querySelector('.checkbox_box_span-text')
		if spanElem.innerHTML == "(0)"
			incrementNumber = parseInt(spanElem.innerHTML.replace(/[()]/g,''),10)
			spanElem.setAttribute('data-max-number',incrementNumber + 1)
		else
			incrementNumber = parseInt(spanElem.innerHTML.replace(/[()]/g,'').split('/')[0],10)
			if incrementNumber + 1 > spanElem.dataset.maxNumber
				spanElem.dataset.maxNumber = incrementNumber + 1
		spanElem.innerHTML = '(' + (incrementNumber+1) + '/' + spanElem.dataset.maxNumber + ')'
		spanElem.closest('.advanced-select_option').classList.remove 'zeroed'
	return
app.AdvancedSelectResetSpans = (targetElem) ->
	for asoElem in targetElem.querySelectorAll '.advanced-select_option'
		spanElem = asoElem.querySelector('.checkbox_box_span-text')
		if spanElem.innerHTML != "(0)"
			spanElem.innerHTML = "(0/" + spanElem.dataset.maxNumber + ")"
		asoElem.classList.add 'zeroed'
	return

# Calendar Entry
class CalendarEntry
	constructor: (el) ->
		@.$el = el
		@.$textContent = @.$el.querySelector '.calendar-entry__content__text'
		@.$readLessBtn = @.$el.querySelector '.calendar-entry__control-link--less'
		@.$readMoreBtn = @.$el.querySelector '.calendar-entry__control-link--more'

		@.daily = @.$el.dataset.daily == "true"

		@.InitEvents()

		@.$el.classList.add 'calendar-entry--binded'

	GenerateDownload: ->
		hashedUid = "lbbcareer_" + (+new Date).toString(36)
		cal = ics(hashedUid)
		subject = @.$el.querySelector('.calendar-entry__content__title .headline').textContent.trim()
		description = ""
		location = ""
		begin = new Date(@.$el.dataset.start)
		end = new Date(@.$el.dataset.end)
		filename = "brandenburg_" + @.$el.dataset.type + "_" + @.$el.dataset.start.replace(".","-").replace(":","-").replace(" ","-") + "_" + @.$el.dataset.end.replace(".","-").replace(":","-").replace(" ","-") + ".ics"
		if @.daily
			days = Math.ceil(Math.abs(new Date(@.$el.dataset.end).setHours(0,0,0,0) - new Date(@.$el.dataset.start).setHours(0,0,0,0)) / (1000 * 60 * 60 * 24)) + 1
			endDaily = new Date(new Date(@.$el.dataset.start).setHours(end.getHours(),end.getMinutes(),0,0))
			# solution with repeated event
			cal.addEvent subject, description, location, begin, endDaily, {
				rrule: 'RRULE:FREQ=DAILY;COUNT=' + days
			}

			# other solution with independent daily events
			# for x in [1..days]
			# 	cal.addEvent subject, description, location, begin, endDaily
			# 	begin = new Date(begin.setDate(begin.getDate() + 1))
			# 	endDaily = new Date(endDaily.setDate(endDaily.getDate() + 1))
		else
			cal.addEvent subject, description, location, begin, end
		cal.download filename


	InitEvents: ->
		self = @
		@.$el.addEventListener 'keypress', (e) ->
			if e.key == "Enter"
				if !e.target.classList.contains('calendar-entry__control-link') && !e.target.classList.contains('link')
					e.preventDefault()
					self.GenerateDownload()
		@.$el.addEventListener 'click', (e) ->
			if !e.target.classList.contains('calendar-entry__control-link') && !e.target.classList.contains('link')
				e.preventDefault()
				self.GenerateDownload()
		if @.$textContent
			@.$readLessBtn.addEventListener 'click', (e) ->
				e.preventDefault()
				self.$textContent.classList.remove 'calendar-entry__content__text--shown'
			@.$readMoreBtn.addEventListener 'click', (e) ->
				e.preventDefault()
				self.$textContent.classList.add 'calendar-entry__content__text--shown'

app.CalendarEntriesBinder = ->
	document.querySelectorAll '.calendar-entry:not(.calendar-entry--binded)'
		.forEach (el) ->
			new CalendarEntry el

# CCT-Select-Box
app.CctSelectBoxChange = (input) ->
	cctSelectBoxElem = input.closest '.cct-select-box'
	answers = JSON.parse cctSelectBoxElem.dataset.answers
	answer = if input.type == "radio" then input.value.split('_')[1] else input.name.split('_')[1].replace('"','')
	if cctSelectBoxElem.dataset.multiple == "false"
		if input.checked
			answers = [answer]
	else
		if input.checked
			answers.push answer
		else
			answers.splice(answers.indexOf(answer),1)
	cctSelectBoxElem.dataset.answers = JSON.stringify answers
	return

# Cookie-Banner
app.CookieBannerBinder = ->
	cookieConsentSetting = window.localStorage.getItem 'lbbCareer.cookieConsentSetting'
	for cbElem in document.querySelectorAll '.cookie-banner'
		if !cookieConsentSetting
			cbElem.classList.add 'show'
		cbElem.querySelector('.cookie-banner_interaction_buttons_accept .btn').addEventListener 'click', (e) ->
			e.preventDefault()
			window.localStorage.setItem 'lbbCareer.cookieConsentSetting', 'accepted'
			if _paq
				_paq.push(['setConsentGiven'])
			@.closest('.cookie-banner').classList.remove 'show'
			return
		cbElem.querySelector('.cookie-banner_interaction_buttons_decline .btn').addEventListener 'click', (e) ->
			e.preventDefault()
			window.localStorage.setItem 'lbbCareer.cookieConsentSetting', 'declined'
			if _paq
				_paq.push(['forgetConsentGiven'])
			@.closest('.cookie-banner').classList.remove 'show'
			return
	if cookieConsentSetting && cookieConsentSetting == "accepted"
		if _paq
			_paq.push(['setConsentGiven'])

	for cboElem in document.querySelectorAll '.cookie-banner-opener'
		cboElem.addEventListener 'click', (e) ->
			e.preventDefault()

			document.querySelector('.cookie-banner').classList.add 'show'
			return
	return

# Header-Flyout
app.HeaderFlyoutBinder = ->
	for headerFlyoutElem in document.querySelectorAll('.header-flyout')
		headerFlyoutElem.querySelector('.header-flyout_closer').addEventListener 'click', (e) ->
			e.preventDefault()
			this.closest('.header-flyout').classList.remove 'active'
			for navElem in document.getElementById('header').querySelectorAll('#header_menu li.open')
				navElem.classList.remove 'open'
			document.getElementById('header').classList.remove 'flyout-open'
			if this.closest('.header-flyout').querySelector('.header-flyout_entry.active')
				this.closest('.header-flyout').querySelector('.header-flyout_entry.active').classList.remove 'active'
			return
	return

# Filter-Bar
class FilterBar
	constructor: (el) ->
		@.$el = el
		@.$filters = @.$el.querySelectorAll '.filter-bar__filter'

		@.InitEvents()
		@.$el.classList.add 'filter-bar--binded'

	InitEvents: ->
		self = @
		@.$filters.forEach (filter) ->
			filter.addEventListener 'click', (e) ->
				self.$el.querySelector('.filter-bar__filter--active').classList.remove 'filter-bar__filter--active'
				@.classList.add 'filter-bar__filter--active'
				self.$el.dispatchEvent new CustomEvent 'filterbarchange', { bubbles: true, detail: {
					filter: @.dataset.filter
				}}
app.FilterBarsBinder = ->
	document.querySelectorAll '.filter-bar:not(.filter-bar--binded)'
		.forEach (el) ->
			new FilterBar el


# Job-Profile-Card
app.JobProfileCardBinder = (target) ->
	if !target
		target = document.querySelectorAll '.job-profile-card'
	for jpcElem in target
		app.BindJobProfileCard jpcElem
	return
app.BindJobProfileCard = (jpcElem) ->
	jpcElem.addEventListener 'click', (e) ->
		if e.target != @.querySelector('.job-profile-card_back_link') && e.target.tagName != "A"
			@.classList.toggle 'flipped'
		return
	return
app.CreateJobProfileCard = (jp, targetElem, order) ->
	jpcElem = document.createElement 'div'
	jpcElem.classList.add 'job-profile-card'
	link = jp.link.url
	linkTarget = if link.indexOf(window.location.host) > -1 then "_self" else "_blank"
	jpcElem.innerHTML = '<div class="job-profile-card_content"><div class="job-profile-card_front"><div class="job-profile-card_front_image" style="background-image:url(' + jp.image.src + ')"></div><div class="job-profile-card_front_title"><h6 class="headline">' + jp.title + '</h6></div></div><div class="job-profile-card_back"><div class="job-profile-card_back_title"><h6 class="headline">' + jp.title + '</h6></div><div class="job-profile-card_back_text">' + jp.text + '</div><div class="job-profile-card_back_link">	<a class="link link-internal" href="' + link + '" title="" target="' + linkTarget + '">' + jp.link.text + ' <svg class="icon" viewBox="0 0 30 30" role="presentation" focusable="false"><use class="" xlink:href="' + window.location.protocol + '//' + window.location.host + '/assets/icons/' + app.hashedIcon + '#icon-arrow_' + (if linkTarget == "_blank" then "extern" else "right") + '"></use></svg></a></div></div></div>'
	if order
		jpcElem.style.order = order
	targetElem.appendChild jpcElem
	app.BindJobProfileCard jpcElem
	return
app.GetJobProfile = (id, targetElem, order) ->
	jobProfileRequest = new XMLHttpRequest()
	jobProfileRequest.onreadystatechange = ->
		if jobProfileRequest.readyState == 4
			if jobProfileRequest.status == 200
				app.CreateJobProfileCard(JSON.parse(jobProfileRequest.responseText), targetElem, order)
	jobProfileRequest.open "GET", window.location.protocol + "//" + window.location.host + "/api/job-profile/" + id, true
	jobProfileRequest.send()
	return

# Offer-Counts
app.UpdateOfferCounts = (targetElem, number) ->
	if targetElem
		if number > 0
			targetElem.querySelector('b').innerHTML = number
			targetElem.querySelector('.offer-counts_hint').classList.add 'hide'
		else
			targetElem.querySelector('b').innerHTML = "Keine"
			targetElem.querySelector('.offer-counts_hint').classList.remove 'hide'
		if number > 1
			text = "offene Stellen"
		else if number < 1
			text = "offenen Stellen"
		else
			text = "offene Stelle"
		tagElemsList = targetElem.querySelectorAll '.offer-counts_tags .tag'
		for tagElem in tagElemsList
			if tagElem.dataset.group == 'area'
				targetElem.querySelector('.offer-counts_default-location').classList.add 'hide'
			else
				targetElem.querySelector('.offer-counts_default-location').classList.remove 'hide'
		if tagElemsList.length < 1
			targetElem.querySelector('.offer-counts_default-location').classList.remove 'hide'
		targetElem.querySelector('.offer-counts_jobs').innerHTML = text
	return

# Offer-Row
app.AddOfferRowElem = (targetElem, id, title, employer, area, city, type, vacancy, typeId, professionId, classificationId, areaId, temporary, workingTime, vacancyDate, onlineDate) ->
	orElem = document.createElement 'a'
	orElem.classList.add 'offer-row'
	orElem.href = window.location.protocol + "//" + window.location.host + "/stellenangebote/" + id
	orElem.dataset.type = typeId
	orElem.dataset.profession = professionId
	orElem.dataset.classification = classificationId
	orElem.dataset.area = areaId
	orElem.dataset.temporary = temporary
	orElem.dataset.workingtime = workingTime
	orElem.dataset.vacancy = vacancyDate
	orElem.dataset.onlineDate = onlineDate
	orElem.innerHTML = '<div class="offer-row_left"><div class="offer-row_title"><h6 class="headline">' + title + '</h6></div><div class="offer-row_meta">' + employer + '</div><div class="offer-row_type">' + type + '</div></div><div class="offer-row_right"><div class="offer-row_area">' + area + ' <svg class="icon" viewBox="0 0 30 30" role="presentation" focusable="false"><use class="" xlink:href="' + window.location.protocol + '//' + window.location.host + '/assets/icons/' + app.hashedIcon + '#icon-location"></use></svg></div><div class="offer-row_city">' + city + '</div><div class="offer-row_vacancy">Bewerbungsfrist ' + vacancy + ' <svg class="icon" viewBox="0 0 30 30" role="presentation" focusable="false"><use class="" xlink:href="' + window.location.protocol + '//' + window.location.host + '/assets/icons/' + app.hashedIcon + '#icon-calendar"></use></svg></div></div>'
	targetElem.appendChild orElem
	return

app.FilterOfferRows = (filters) ->
	offerCount = 0
	offerRows = document.querySelectorAll '.offer-row'
	matchElems = []
	matchElemsGrouped = []
	for groupElems, index in app.filter.groups
		matchElemsGrouped[index] = []
	for orElem in offerRows
		orElem.classList.add 'no-match'
		matchIndex = matchElems.indexOf(orElem)
		if filters.length > 0
			for filterPair in filters
				if orElem.dataset[filterPair.type] == filterPair.value
		
					if matchIndex == -1
						matchElems.push orElem

					for group, index in matchElemsGrouped
						if app.filter.groups[index].indexOf(filterPair.type) > -1
							if group.indexOf(orElem) == -1
								group.push orElem
		else
			#offerCount = if app.offers then app.offers.length else if document.querySelector('.offer-results') then document.querySelectorAll('.offer-results .offer-row').length else 0
			orElem.classList.remove 'no-match'
			offerCount = offerRows.length
	finalMatchElems = []
	for match in matchElems
		noMatch = false
		for group in matchElemsGrouped
			if group.length > 0
				if group.indexOf(match) == -1
					noMatch = true
		if !noMatch
			if finalMatchElems.indexOf(match) == -1
				finalMatchElems.push match
		
	for orMatch in finalMatchElems
		orMatch.classList.remove 'no-match'
	if finalMatchElems.length > 0
		offerCount = finalMatchElems.length
	window.localStorage.setItem('active-filters', JSON.stringify(filters))
	app.UpdateOfferCounts document.querySelector('.offer-counts'), offerCount

	return

app.SortOfferRows = (value, direction, targetElem) ->
	offerRows = targetElem.querySelectorAll '.offer-row'
	offerRowsArray = Array.prototype.slice.call offerRows, 0
	if offerRowsArray.length > 0
		offerRowsArray.sort (a, b) ->
			if direction == "asc"
				return a.dataset[value] - b.dataset[value]
			else
				return b.dataset[value] - a.dataset[value]
	
	orListElem = targetElem.querySelector '.offer-results_list'
	count = 0
	for orElem in offerRows
		orListElem.append offerRowsArray[count]
		count++
	
	app.OfferResultsShowOffers targetElem

	return


# Scroll-Down-Hint
app.ScrollDownHintBinder = ->
	for sdhElem in document.querySelectorAll '.scroll-down-hint'
		sdhElem.addEventListener 'click', (e) ->
			contentElem = document.getElementById('content')
			if app.HasClass contentElem.firstChild, 'content-module'
				app.ScrollToElem contentElem.querySelectorAll('.content-module')[1], 0, false, true
			else
				app.ScrollToElem contentElem.querySelectorAll('.content-module')[0], 0, false, true
			return
	document.addEventListener 'scroll', (e) ->
		if window.scrollY >= window.innerHeight
			for sdhElem in document.querySelectorAll '.scroll-down-hint'
				if !app.HasClass(sdhElem, 'integrated')
					sdhElem.remove()
		return
	return

# Search-Bar
app.SearchBarBinder = ->
	for searchBarElem in document.querySelectorAll('.search-bar.flyout')
		searchBarElem.addEventListener 'click', (e) ->
			e.stopPropagation()
			return
		searchBarElem.querySelector('input[type="text"]').addEventListener 'focus', (e) ->
			this.closest('.search-bar.flyout').classList.add 'flyout-focus'
			if this.value != ""
				if window.innerWidth > 768
					app.SearchBarOpenFlyout this.closest('.search-bar.flyout')
			return
		searchBarElem.querySelector('input[type="text"]').addEventListener 'blur', (e) ->
			this.closest('.search-bar.flyout').classList.remove 'flyout-focus'
			return
		searchBarElem.querySelector('input[type="text"]').addEventListener 'change', (e) ->
			if this.value != ""
				if window.innerWidth > 768
					app.SearchBarOpenFlyout this.closest('.search-bar.flyout')
			else
				this.closest('.search-bar.flyout').classList.remove 'flyout-open'
			return
		searchBarElem.querySelector('input[type="text"]').addEventListener 'click', (e) ->
			if window.innerWidth > 768
				if app.searchBarFirstClick
					app.searchBarFirstClick = false
					app.CheckForOffersUpdate 'search-bar-flyout'
			return
		searchBarElem.querySelector('input[type="text"]').addEventListener 'keyup', (e) ->
			if e.target.value.trim() != app.lastSearchParam
				app.lastSearchParam = e.target.value.trim()
				window.clearInterval app.searchBarInterval
				app.searchBarInterval = window.setInterval ->
					if e.target.value.length >= 3
						window.clearInterval app.searchBarInterval
						if searchBarElem.querySelectorAll('.simple-offer-row').length > 0
							searchBarElem.querySelector('.search-bar_flyout_offers_extended').classList.remove 'hide'
							app.GetMatchingOffersBySearchparam e.target.value
						else
							app.redoExtendedSearch = true
					return
				, app.searchBarDelay
				if window.innerWidth > 768
					if app.searchBarFirstClick
						app.searchBarFirstClick = false
						app.CheckForOffersUpdate 'search-bar-flyout'
					else
						searchBarElem = this.closest('.search-bar.flyout')
						app.SearchBarUpdateScrollBar(searchBarElem)

					if this.value.length > 0
						app.SearchBarOpenFlyout this.closest('.search-bar.flyout')
						app.SearchBarUpdateOffers this.closest('.search-bar.flyout'), this.value
					else
						this.closest('.search-bar.flyout').classList.remove 'flyout-open'
			return
		searchBarElem.querySelector('.search-bar_flyout_offers_list').addEventListener 'scroll', (e) ->
			listElem = this
			scrollElem = this.closest('.search-bar.flyout').querySelector('.search-bar_flyout_offers_list-scroll')
			scrollBarElem = scrollElem.querySelector('.search-bar_flyout_offers_list-scroll_bar')
			if e.target.scrollTop > 0
				translate = ((e.target.scrollTop / (listElem.scrollHeight - listElem.offsetHeight)) * (scrollElem.offsetHeight - scrollBarElem.offsetHeight)) + 'px'
			else
				translate = 0
			scrollBarElem.style.transform = "translateY(" + translate + ")"
			return
	if document.querySelectorAll('.search-bar.flyout').length > 0
		document.addEventListener 'click', (e) ->
			for searchBarElem in document.querySelectorAll('.search-bar.flyout')
				if app.HasClass searchBarElem, 'flyout-open'
					outsideClick = true
					if e.target == searchBarElem
						outsideClick = false
					for searchBarChildNode in searchBarElem.childNodes
						if e.target == searchBarChildNode
							outsideClick = false
					if outsideClick
						searchBarElem.classList.remove 'flyout-open'			
	return
app.SearchBarAppendOffers = (searchBarElem) ->
	listElem = searchBarElem.querySelector('.search-bar_flyout_offers_list')
	for offer in app.offers
		listElem.append app.CreateSimpleOfferRow(offer)
	if app.redoExtendedSearch
		app.redoExtendedSearch = false
		document.querySelector('.search-bar_flyout_offers_extended').classList.remove 'hide'
		app.GetMatchingOffersBySearchparam app.lastSearchParam
	return
app.SearchBarOpenFlyout = (searchBarElem) ->
	if app.HasClass searchBarElem, 'flyout'
		if !app.HasClass(searchBarElem, 'flyout-open')
			searchBarElem.classList.add 'flyout-open'
			window.setTimeout ->
				app.SearchBarUpdateScrollBar(searchBarElem)
				return
			, 300
	return
app.SearchBarRemoveProgress = ->
	for searchBarElem in document.querySelectorAll('.search-bar.flyout')
		searchBarElem.querySelector('.search-bar_flyout_offers_preload').classList.add 'hide'
		app.SearchBarAppendOffers searchBarElem
	return
app.SearchBarUpdateOffers = (searchBarElem, string) ->
	for sorElem in searchBarElem.querySelectorAll('.simple-offer-row')
		searchFound = false
		if sorElem.querySelector('.simple-offer-row_title').innerText.search(string) >= 0
			searchFound = true
		if sorElem.querySelector('.simple-offer-row_city').innerText.search(string) >= 0
			searchFound = true
		if searchFound
			sorElem.classList.remove 'hide'
		else
			sorElem.classList.add 'hide'
	return
app.SearchBarUpdateOffersExtendedSearch = ->
	offersListElem = document.querySelector '.search-bar_flyout_offers_list'
	if offersListElem
		app.matchingOffers.forEach (offer) ->
			matchingSimpleOfferRowElem = offersListElem.querySelector ".simple-offer-row[data-id=\"#{ offer.id }\"]"
			if matchingSimpleOfferRowElem
				matchingSimpleOfferRowElem.classList.remove 'hide'
			return
	document.querySelector('.search-bar_flyout_offers_extended').classList.add 'hide'
	return
app.SearchBarUpdateScrollBar = (searchBarElem) ->
	scrollBarElem = searchBarElem.querySelector('.search-bar_flyout_offers_list-scroll_bar')
	listElem = searchBarElem.querySelector('.search-bar_flyout_offers_list')
	scrollBarElem.style.height = (100 / (listElem.scrollHeight / listElem.offsetHeight)) + '%'
	if listElem.scrollHeight <= listElem.offsetHeight
		scrollBarElem.closest('.search-bar_flyout_offers_list-scroll').classList.add 'disabled'
	else
		scrollBarElem.closest('.search-bar_flyout_offers_list-scroll').classList.remove 'disabled'
	scrollBarElem.style.transform = 'translateY(0)'
	listElem.scrollTo(0,0)
	return

# Search Filter
app.SearchFilterBinder = ->
	for sfElem in document.querySelectorAll('.search-filter')
		for ftElem in sfElem.querySelectorAll('.filter-tag')
			ftElem.addEventListener 'click', (e) ->
				if sfElem.querySelectorAll('.filter-tag.active').length < 1
					for sorElem in document.querySelectorAll('.simple-offer-row')
						sorElem.classList.remove 'filtered'
				else
					filterAttr = this.dataset.filterId.substr(0, this.dataset.filterId.indexOf('_'))
					filterId = this.dataset.filterId.substr(this.dataset.filterId.indexOf('_')+ 1)
					for sorElem in document.querySelectorAll('.simple-offer-row')
						if !app.HasClass(sorElem, 'hide')
							filterOn = parseInt(sorElem.dataset.filterOn)
							if sorElem.dataset[filterAttr] == filterId
								if app.HasClass this, 'active'
									sorElem.classList.remove 'filtered'
									sorElem.dataset.filterOn = filterOn + 1
								else
									sorElem.dataset.filterOn = filterOn - 1
							else
								if app.HasClass this, 'active'
									if filterOn < 1
										sorElem.classList.add 'filtered'
				searchBarElem = this.closest('.search-bar.flyout')
				if searchBarElem
					app.SearchBarUpdateScrollBar searchBarElem
				return
	return

# Simple Offer Row
app.CreateSimpleOfferRow = (offer) ->
	sorElem = document.createElement 'a'
	sorElem.classList.add 'simple-offer-row'
	sorElem.classList.add 'hide'
	sorElem.dataset.type = offer.type
	sorElem.dataset.id = offer.id
	sorElem.dataset.workingtime = offer.workingTime
	sorElem.dataset.filterOn = "0"
	sorElem.innerHTML = '<div class="simple-offer-row_title">' + (if offer.title then offer.title else '<span class="missing">keine Angabe</span>') + '</div><div class="simple-offer-row_city">' + (if offer.city then offer.city else '<span class="missing">keine Angabe</span>') + '</div>'
	sorElem.href = './stellenangebote/' + offer.id
	return sorElem

# To-Top-Button
app.ToTopButtonBinder = ->
	app.toTopButtonElem = document.querySelector('.to-top-button')
	if app.toTopButtonElem
		app.toTopButtonElem.querySelector('.btn').addEventListener 'click', (e) ->
			e.preventDefault()
			window.scrollTo {
				top: 0, 
				left: 0,
				behavior: "smooth" 
			}
			return
	return


# #########
# ORGANISMS
# #########

# Accordion
app.AccordionBinder = ->
	for accordionElem in document.querySelectorAll '.accordion'
		for accordionHeaderElem in accordionElem.querySelectorAll '.accordion_row_header'
			accordionHeaderElem.addEventListener 'click', (e) ->
				e.preventDefault()
				this.closest('.accordion_row').classList.toggle 'open'
				return
	return

# Advanced-Select-Row
app.AdvancedSelectRowBinder = ->
	for asrElem in document.querySelectorAll('.advanced-select-row')
		for checkboxElem in asrElem.querySelectorAll('.checkbox')
			app.SetId checkboxElem, 'asr-checkbox_'
			checkboxElem.querySelector('input[type="checkbox"]').addEventListener 'change', (e) ->
				if @.checked
					textElem = document.createElement 'div'
					textElem.innerHTML = @.closest('.checkbox').innerHTML
					textElem.querySelector('.checkbox_box').remove()
					textElem.querySelector('span').remove()
					tagTarget = if document.querySelector('.offer-counts') then document.querySelector('.offer-counts .offer-counts_tags') else @.closest('.advanced-select-row').querySelector('.advanced-select-row_tags')
					app.CreateTag textElem.innerText, tagTarget, @.closest('.advanced-select_content_section').dataset.prefix, '', @.closest('.checkbox').id, @.closest('.advanced-select_content_section').dataset.prefixPlural, @.name.split("_")[0].replaceAll('"', '')
					document.getElementById('tag_' + @.closest('.checkbox').id).addEventListener 'click', (e) ->
						checkboxInput = document.getElementById(@.id.substring(4,@.id.length)).querySelector('input[type="checkbox"]')
						checkboxInput.checked = false
						app.TriggerEvent checkboxInput, 'change'
						return
				else
					if document.getElementById('tag_' + @.closest('.checkbox').id)
						document.getElementById('tag_' + @.closest('.checkbox').id).remove()
				return
	return

# Calendar-Overview
class CalendarOverview
	constructor: (el) ->
		@.$el = el
		@.$entries = @.$el.querySelectorAll '.calendar-entry'
		@.$filterBar = @.$el.querySelector '.filter-bar'

		@.InitEvents()
		@.$el.classList.add 'calendar-overview--binded'
	
	FilterEntries: (filterName) ->
		@.$entries.forEach (entry) ->
			if entry.dataset.type == filterName || filterName == 'all'
				entry.classList.remove 'calendar-overview__entry--hidden'
			else
				entry.classList.add 'calendar-overview__entry--hidden'

	InitEvents: ->
		self = @
		@.$filterBar.addEventListener 'filterbarchange', (e) ->
			self.FilterEntries e.detail.filter
app.CalenderOverviewBinder = ->
	document.querySelectorAll '.calendar-overview:not(.calendar-overview--binded)'
		.forEach (el) ->
			new CalendarOverview el

# Career Choice Test
app.CareerChoiceTestBinder = ->
	cctElemsList = document.querySelectorAll '.career-choice-test'
	for cctElem in cctElemsList
		questionsElem = cctElem.querySelector '.career-choice-test_questions'
		
		startButton = cctElem.querySelector '.career-choice-test_start_box_button .btn'

		backButton = cctElem.querySelector '.career-choice-test_questions_control_left_back .iterator-button'
		skipButton = cctElem.querySelector '.career-choice-test_questions_control_right_skip .link'
		nextButton = cctElem.querySelector '.career-choice-test_questions_control_right_next .iterator-button'

		redoButton = cctElem.querySelector '.career-choice-test_result_control_redo .iterator-button'
		offersButton = cctElem.querySelector '.career-choice-test_result_control_offers .iterator-button'

		if questionsElem.querySelectorAll('.career-choice-test_questions_question').length <= 1
			skipButton.parentNode.classList.add 'hide'

		# start button binder
		startButton.addEventListener 'click', (e) ->
			e.preventDefault()
			id = @.closest('.career-choice-test').id
			cctId = @.closest('.career-choice-test').dataset.id
			app.elems[id] = {
				'answers': undefined,
				'answerJobProfileMatches': undefined,
				'filterSettings': {
					'area': parseInt(cctElem.dataset.filterArea,10),
					'classification': parseInt(cctElem.dataset.filterClassification,10),
					'profession': parseInt(cctElem.dataset.filterProfession,10),
					'type': parseInt(cctElem.dataset.filterType,10)
				},
				'jobProfiles': []
			}
			app.CareerChoiceTestGetJobProfiles id, cctId
			document.getElementById(id).classList.add 'started'
			return

		# question iteration control binders
		backButton.addEventListener 'click', (e) ->
			e.preventDefault()
			app.CareerChoiceTestIterate this.closest('.career-choice-test'), 'back'
			return

		skipButton.addEventListener 'click', (e) ->
			e.preventDefault()
			app.CareerChoiceTestIterate this.closest('.career-choice-test'), 'skip'
			return

		nextButton.addEventListener 'click', (e) ->
			e.preventDefault()
			app.CareerChoiceTestIterate this.closest('.career-choice-test'), 'next'
			return
		
		# result buttons binders
		redoButton.addEventListener 'click', (e) ->
			e.preventDefault()
			app.CareerChoiceTestReset @.closest('.career-choice-test').id
			return
		
		offersButton.addEventListener 'click', (e) ->
			e.preventDefault()
			app.CareerChoiceOfferRedirect @.closest('.career-choice-test').id
			return
		
		# question input binders
		for inputElem in cctElem.querySelectorAll('input[type="radio"], input[type="checkbox"], input[type="range"]')
			inputElem.addEventListener 'change', (e) ->
				if !app.HasClass(@,'ignore')
					nextElem = @.closest('.career-choice-test').querySelector '.career-choice-test_questions_control_right_next'
					question = @.closest '.career-choice-test_questions_question'
					answers = JSON.parse question.dataset.answers
					
					if @.type == "range"
						answers = [@.value]
						@.closest('.cct-answer-range').querySelector('.cct-answer-range_answer-mobile').innerHTML = @.closest('.cct-answer-range').querySelector('.cct-answer[data-answer-no="' + @.value + '"]').textContent
					else
						if @.closest('.cct-select-boxes')
							app.CctSelectBoxChange @
							answers = []
							allAnswered = true
							givenAnswers = []
							for cctSelectBox in @.closest('.cct-select-boxes').querySelectorAll '.cct-select-box'
								cctSelectBoxAnswers = JSON.parse cctSelectBox.dataset.answers
								for cctSelectBoxAnswer in cctSelectBoxAnswers
									givenAnswers.push cctSelectBoxAnswer
								if cctSelectBoxAnswers.length < 1
									allAnswered = false
							if allAnswered
								answers = givenAnswers
						else
							answerNo = @.closest('.cct-answer').dataset.answerNo
							if @.checked
								if @.type == "radio"
									answers = [answerNo]
								else
									answers.push answerNo
							else
								answers.splice(answers.indexOf(answerNo),1)
					if answers.length > 0
						nextElem.classList.remove 'disable'
					else
						nextElem.classList.add 'disable'
					question.dataset.answers = JSON.stringify answers
				return

	return
app.CareerChoiceTestIterate = (cctElem, iteration) ->
	questionsElem = cctElem.querySelector '.career-choice-test_questions'
	questions = questionsElem.querySelectorAll '.career-choice-test_questions_question'
	nextElem = cctElem.querySelector '.career-choice-test_questions_control_right_next'
	skipElem = cctElem.querySelector '.career-choice-test_questions_control_right_skip'
	backElem = cctElem.querySelector '.career-choice-test_questions_control_left_back'

	activeQuestion = parseInt(questionsElem.dataset.active,10)
	actualQuestion = questionsElem.querySelector('.career-choice-test_questions_question.active')
	if iteration == "skip" || iteration == "next"
		questionAfter = activeQuestion
		if questionAfter <= questions.length
			if questions[questionAfter]
				actualQuestion.classList.remove 'active'
				actualQuestion = questions[questionAfter]
				actualQuestion.classList.add 'active'
				activeQuestion = questionAfter + 1
		if (iteration == "next" || iteration == "skip") && questionAfter == questions.length
			if app.HasClass cctElem, 'cct-jp-set'
				app.CareerChoiceTestEvaluate cctElem.id, true

	if iteration == "back"
		questionBefore = activeQuestion - 2
		if questionBefore >= 0
			if questions[questionBefore]
				actualQuestion.classList.remove 'active'
				actualQuestion = questions[questionBefore]
				actualQuestion.classList.add 'active'
				activeQuestion = questionBefore + 1
	
	questionsElem.dataset.active = activeQuestion
	cctElem.querySelector('.career-choice-test_questions_counter_actual').innerHTML = activeQuestion

	# if activeQuestion == questions.length
	# 	skipElem.classList.add 'hide'
	# else
	# 	skipElem.classList.remove 'hide'

	if activeQuestion > 1
		backElem.classList.add 'show'
	else
		backElem.classList.remove 'show'
	
	if actualQuestion.dataset.answers == "[]"
		nextElem.classList.add 'disable'
	else
		nextElem.classList.remove 'disable'

	return

app.CareerChoiceTestGetJobProfiles = (id, cctId) ->
	if app.elems[id]
		if app.elems[id].jobProfiles.length < 1
			cctJobProfilesRequest = new XMLHttpRequest()
			cctJobProfilesRequest.onreadystatechange = ->
				if cctJobProfilesRequest.readyState == 4
					if cctJobProfilesRequest.status == 200
						app.elems[id].jobProfiles = JSON.parse cctJobProfilesRequest.responseText
						document.getElementById(id).classList.add 'cct-jp-set'
			cctJobProfilesRequest.open "GET", window.location.protocol + "//" + window.location.host + "/api/cct-job-profiles/" + cctId, true
			cctJobProfilesRequest.send()
	return

app.CareerChoiceTestEvaluate = (id, percentAsPoints) ->
	cctElem = document.getElementById id
	cctElem.classList.add 'result'
	questionElems = cctElem.querySelectorAll '.career-choice-test_questions_question'
	answers = {
		'type': {},
		'profession': {},
		'classification': {},
		'area': {}
	}
	answerJobProfileMatches = {
		'type': {},
		'profession': {},
		'classification': {},
		'area': {},
		'overall': {}
	}
	for question in questionElems
		answerNos = JSON.parse question.dataset.answers
		for answerNo in answerNos
			answer = question.querySelector '.cct-answer[data-answer-no="' + answerNo + '"]'
			for typeVal in JSON.parse answer.dataset.types
				if answers.type.hasOwnProperty(typeVal)
					answers.type[typeVal]++
				else
					answers.type[typeVal] = 1
			for professionVal in JSON.parse answer.dataset.professions
				if answers.profession.hasOwnProperty(professionVal)
					answers.profession[professionVal]++
				else
					answers.profession[professionVal] = 1
			for classificationVal in JSON.parse answer.dataset.classifications
				if answers.classification.hasOwnProperty(classificationVal)
					answers.classifications[classificationVal]++
				else
					answers.classification[classificationVal] = 1
			for areaVal in JSON.parse answer.dataset.areas
				if answers.area.hasOwnProperty(areaVal)
					answers.area[areaVal]++
				else
					answers.area[areaVal] = 1
	
	app.elems[id].answers = answers

	for jobProfile in app.elems[id].jobProfiles
		typeMatch = 0
		typeCount = 0
		professionMatch = 0
		professionCount = 0
		classificationMatch = 0
		classificationCount = 0
		areaMatch = 0
		areaCount = 0
		for category in jobProfile.categories
			
			for typeId, count of app.elems[id].answers.type
				if category.id.toString() == typeId
					typeMatch += category.match * count
					typeCount += 1 * count
			
			for professionId, count of app.elems[id].answers.profession
				if category.id.toString() == professionId
					professionMatch += category.match * count
					professionCount += 1 * count
			
			for classificationId, count of app.elems[id].answers.classification
				if category.id.toString() == classificationId
					classificationMatch += category.match * count
					classificationCount += 1 * count
			
			for areaId, count of app.elems[id].answers.area
				if category.id.toString() == areaId
					areaMatch += category.match * count
					areaCount += 1 * count

		if percentAsPoints
			answerJobProfileMatches.type[jobProfile.id] = typeMatch
			answerJobProfileMatches.profession[jobProfile.id] = professionMatch
			answerJobProfileMatches.classification[jobProfile.id] = classificationMatch
			answerJobProfileMatches.area[jobProfile.id] = areaMatch
			answerJobProfileMatches.overall[jobProfile.id] = typeMatch + professionMatch + classificationMatch + areaMatch
		else
			answerJobProfileMatches.type[jobProfile.id] = if typeCount > 0 then (typeMatch / typeCount) else 0
			answerJobProfileMatches.profession[jobProfile.id] = if professionCount > 0 then (professionMatch / professionCount) else 0
			answerJobProfileMatches.classification[jobProfile.id] = if classificationCount > 0 then (classificationMatch / classificationCount) else 0
			answerJobProfileMatches.area[jobProfile.id] = if areaCount > 0 then (areaMatch / areaCount) else 0
			answerJobProfileMatches.overall[jobProfile.id] = (typeMatch + professionMatch + classificationMatch + areaMatch) / 4
	app.elems[id].answerJobProfileMatches = answerJobProfileMatches

	app.CareerChoiceTestBindResult id

	return

app.CareerChoiceTestBindResult = (id) ->
	cctElem = document.getElementById(id)
	sortedJobProfileMatches = []
	
	# sort the jobProfiles by match points
	for id, value of app.elems[id].answerJobProfileMatches.overall
		sortedJobProfileMatches.push [id, value]
	sortedJobProfileMatches.sort (a, b) ->
		return b[1] - a[1]
	
	# place all top 3 jobProfiles - same place with equal match points
	placedJobProfileValues = [0,0,0]
	placedJobProfiles = [[],[],[]]
	for jobProfile in sortedJobProfileMatches
		if placedJobProfiles.length == 0
			placedJobProfiles[0].push jobProfile
			placedJobProfileValues[0] = jobProfile[1]
		if jobProfile[1] >= placedJobProfileValues[0]
			placedJobProfiles[0].push jobProfile
			placedJobProfileValues[0] = jobProfile[1]
		else if jobProfile[1] >= placedJobProfileValues[1]
			placedJobProfiles[1].push jobProfile
			placedJobProfileValues[1] = jobProfile[1]
		else if jobProfile[1] >= placedJobProfileValues[2]
			placedJobProfiles[2].push jobProfile
			placedJobProfileValues[2] = jobProfile[1]

	# get up to 3 top jobProfiles by random picking - starting with place 3
	round = 1
	placesLoaded = 0
	loadableProfiles = []
	for i in [3..1]
		for j in [1..round-placesLoaded]
			if placedJobProfiles[i-1].length > 0
				randomIndex = Math.floor(Math.random() * placedJobProfiles[i-1].length)
				jobProfile = placedJobProfiles[i-1][randomIndex]
				placedJobProfiles[i-1].splice randomIndex, 1
				loadableProfiles.push jobProfile
				placesLoaded++
		round++

	# reverse the loadable array to start with the best one and load the top 3 jobProfiles
	loadableProfiles = loadableProfiles.reverse()
	count = 0
	for jobProfile in loadableProfiles		
		app.GetJobProfile jobProfile[0], cctElem.querySelector('.career-choice-test_result_matches'), count
		count++
		
	return

app.CareerChoiceTestLoadPlaced = (jobProfiles, place) ->
	if jobProfiles.length > 0
		randomIndex = Math.floor(Math.random() * jobProfiles.length)
		jobProfile = jobProfiles[randomIndex]
		jobProfiles.splice randomIndex, 1
		app.GetJobProfile jobProfile[0], cctElem.querySelector('.career-choice-test_result_matches'), place
	return jobProfiles

app.CareerChoiceOfferRedirect = (id) ->	
	answers = app.elems[id].answers
	filters = []
	filterSettings = app.elems[id].filterSettings
	for type, typeObject of answers
		if filterSettings[type] > 0
			for value, count of typeObject
				if count >= filterSettings[type]
					filter = {'type': type, 'value': value}
					filters.push filter
		window.localStorage.setItem('active-filters', JSON.stringify(filters))
	window.location.href = window.location.protocol + "//" + window.location.host + "/stellenangebote"
	return

app.CareerChoiceTestReset = (id) ->
	cctElem = document.getElementById id
	cctElem.querySelector('form').reset()
	questionsList = cctElem.querySelectorAll '.career-choice-test_questions_question'
	for question in questionsList
		question.dataset.answers = "[]"
		question.classList.remove 'active'
	questionsList[0].classList.add 'active'

	# range reset
	for rangeMobileAnswer in cctElem.querySelectorAll '.cct-answer-range_answer-mobile'
		rangeMobileAnswer.innerHTML = ""
	# map reset
	for mapAreaElem in cctElem.querySelectorAll '.lbbcareer_map_areas_area'
		mapAreaElem.classList.remove 'active'

	cctElem.querySelector('.career-choice-test_questions').dataset.active = 1

	cctElem.querySelector('.career-choice-test_questions_control_right_next').classList.add 'disable'
	cctElem.querySelector('.career-choice-test_questions_control_left_back').classList.remove 'show'
	if questionsList.length <= 1
		cctElem.querySelector('.career-choice-test_questions_control_right_skip').classList.add 'hide'
	else
		cctElem.querySelector('.career-choice-test_questions_control_right_skip').classList.remove 'hide'


	cctElem.querySelector('.career-choice-test_result_matches').innerHTML = ""

	cctElem.querySelector('.career-choice-test_questions_counter_actual').innerHTML = 1

	cctElem.classList.remove 'result'
	cctElem.classList.remove 'started'
	return

# CCT-Map
app.CctMapBinder = ->
	for cctMapElem in document.querySelectorAll '.cct-map'
		for inputElem in cctMapElem.querySelectorAll '.cct-check-radio input'
			inputElem.addEventListener 'change', (e) ->
				if !app.HasClass(@, 'programmatic') && !app.HasClass(@, 'ignore')
					app.MapAreaEvent @.closest('.cct-map').querySelector('.map'), @.closest('.cct-map_content_boxes_box').dataset.area
				if app.HasClass(@,'all') || app.HasClass(@.closest('label'), 'all')
					answers = []
					if @.checked
						text = "Alle abwählen"
					else
						text = "Alle auswählen"
					@.closest('.cct-map_content_boxes_box').querySelector('.cct-map_content_boxes_box_text').textContent = text
					for mapInputElem in @.closest('.cct-map_content_boxes').querySelectorAll '.cct-map_content_boxes_box.cct-answer .cct-check-radio input'
						if @.checked != mapInputElem.checked
							mapInputElem.click()
						if @.checked
							answers.push mapInputElem.closest('.cct-map_content_boxes_box').dataset.answerNo
					@.closest('.career-choice-test_questions_question').dataset.answers = JSON.stringify answers
				return
		for cctMapBox in cctMapElem.querySelectorAll '.cct-map_content_boxes_box'
			cctMapBox.addEventListener 'mouseenter', (e) ->
				areaElem = @.closest('.cct-map').querySelector('.map .lbbcareer_map_areas_area[data-mapping="' + @.dataset.area + '"]')
				if areaElem
					areaElem.classList.add 'hover'
				return
			cctMapBox.addEventListener 'mouseleave', (e) ->
				areaElem = @.closest('.cct-map').querySelector('.map .lbbcareer_map_areas_area[data-mapping="' + @.dataset.area + '"]')
				if areaElem
					areaElem.classList.remove 'hover'
				return
		for mapArea in cctMapElem.querySelectorAll '.map .lbbcareer_map_areas_area'
			mapArea.addEventListener 'click', (e) ->
				cctMapBox = @.closest('.cct-map').querySelector('.cct-map_content_boxes_box[data-area="' + @.dataset.mapping + '"]')
				if cctMapBox
					cctMapBox.querySelector('input').classList.add 'programmatic'
					cctMapBox.click()
					cctMapBox.querySelector('input').classList.remove 'programmatic'
				return
		
	return

app.CctRangeSliderBinder = ->
	for crsElem in document.querySelectorAll '.cct-range-slider_slider'
		crsElem.querySelector('input[type="range"]').addEventListener 'click', (e) ->
			if `"createEvent" in document`
				evt = document.createEvent "HTMLEvents"
				evt.initEvent "change", false, true
				@.dispatchEvent evt
			else
				@.fireEvent "onchange"
	return

# Contact
app.ContactBinder = ->
	for contactElem in document.querySelectorAll('.contact-container')
		app.SetId contactElem, 'contact_'
		id = contactElem.id

		submitBtn = contactElem.querySelector '.contact-container_left_contact-form_submit_button button'

		for validateElem in contactElem.querySelectorAll '.contact-container_left_contact-form input, .contact-container_left_contact-form textarea'
			validateElem.addEventListener 'change', (e) ->
				app.ContactValidate this.closest '.contact-container'
				if this.type == "checkbox"
					this.dataset.firstFilled = true
				return
			validateElem.addEventListener 'keyup', (e) ->
				app.ContactValidate this.closest '.contact-container'
				this.dataset.firstFilled = true
				return

		submitBtn.addEventListener 'click', (e) ->
			e.preventDefault()
			app.ContactSubmit this.closest '.contact-container'
			return
		contactElem.querySelector('form').addEventListener 'submit', (e) ->
			e.preventDefault()
			app.ContactSubmit this.closest '.contact-container'
			return
	return
app.ContactSubmit = (contactElem) ->
	contactElem.querySelector('.contact-container_left_contact-form_input-preventer').classList.add 'shown'

	formData = new FormData()
	formData.append 'name', contactElem.querySelector('.contact-container_left_contact-form_name').value
	formData.append 'email', contactElem.querySelector('.contact-container_left_contact-form_mail').value
	formData.append 'messageText', contactElem.querySelector('.contact-container_left_contact-form_text').value
	formData.append 'receiverMailAddress', contactElem.querySelector('form').dataset.receivermail

	contactRequest = new XMLHttpRequest()
	contactRequest.onreadystatechange = ->
		if contactRequest.readyState == 4
			if contactRequest.status == 200
				contactResponse = JSON.parse contactRequest.responseText
				if contactResponse.success
					contactElem.querySelector('.contact-container_left_contact-form_overlay').classList.add 'shown'
	contactRequest.open "POST", contactElem.querySelector('form').getAttribute('action')
	contactRequest.send(formData)

	return
app.ContactValidate = (contactElem) ->
	valid = true
	allSet = true
	submitBtn = contactElem.querySelector '.contact-container_left_contact-form_submit_button button'
	for validateElem in contactElem.querySelectorAll '.contact-container_left_contact-form input, .contact-container_left_contact-form textarea'
		validateElem.classList.remove 'error'
		validateElem.setAttribute 'aria-invalid', 'false'
		if validateElem.type != "checkbox"
			validateElem.closest('.contact-container_left_contact-form_row').querySelector('.contact-container_left_contact-form_error-msg').classList.remove 'show'
			if validateElem.dataset.firstFilled == "true"
				if validateElem.value == ""
					valid = false
					validateElem.classList.add 'error'
					validateElem.setAttribute 'aria-invalid', 'true'
					validateElem.closest('.contact-container_left_contact-form_row').querySelector('.contact-container_left_contact-form_error-msg').classList.add 'show'
				if validateElem.type == "email"
					mailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					if !mailCheck.test(String(validateElem.value).toLowerCase())
						valid = false
						validateElem.classList.add 'error'
						validateElem.setAttribute 'aria-invalid', 'true'
						validateElem.closest('.contact-container_left_contact-form_row').querySelector('.contact-container_left_contact-form_error-msg').classList.add 'show'
			else
				allSet = false
		else
			if !validateElem.checked
				valid = false
			else
				validateElem.setAttribute 'aria-invalid', 'false'
				validateElem.closest('.contact-container_left_contact-form_submit_checkbox').querySelector('.contact-container_left_contact-form_submit_error-msg').classList.remove 'show'
				validateElem.closest('.checkbox').classList.remove 'error'
			if validateElem.dataset.firstFilled == "true"
				if !validateElem.checked
					validateElem.setAttribute 'aria-invalid', 'true'
					validateElem.closest('.contact-container_left_contact-form_submit_checkbox').querySelector('.contact-container_left_contact-form_submit_error-msg').classList.add 'show'
					validateElem.closest('.checkbox').classList.add 'error'


	if allSet && valid
		submitBtn.disabled = false
	else
		submitBtn.disabled = true
	return

# Feature Image Slider
app.FeatureImageSliderBinder = ->
	for fisElem in document.querySelectorAll '.features-image-slider'
		app.SetId fisElem, 'fis_'
		app.elems[fisElem.id] = {
			'aniInterval': undefined,
			'actSlide': 1
		}
		slides = fisElem.querySelectorAll '.features-image-slider_content-block li'
		nextBtn = fisElem.querySelector '.features-image-slider_container_right_content_buttons_right .btn'
		prevBtn = fisElem.querySelector '.features-image-slider_container_right_content_buttons_left .btn'
		if slides.length > 1
			nextBtn.addEventListener 'click', (e) ->
				e.preventDefault()
				app.FeatureImageSliderEvent this.closest('.features-image-slider'), 'next'
				return
			prevBtn.addEventListener 'click', (e) ->
				e.preventDefault()
				app.FeatureImageSliderEvent this.closest('.features-image-slider'), 'prev'
				return
			for titleElem in fisElem.querySelectorAll '.features-image-slider_container_left_links_elem'
				titleElem.addEventListener 'click', (e) ->
					e.preventDefault()
					app.FeatureImageSliderEvent this.closest('.features-image-slider'), Array.from(this.parentNode.children).indexOf(this) + 1
					return
		else
			# uncomment following line to fully hide buttons row
			#fisElem.querySelector('.features-image-slider_container_right_content_buttons').classList.add 'hide'
			nextBtn.classList.remove 'primary'
			nextBtn.disabled = true
			prevBtn.disabled = true
	return
app.FeatureImageSliderEvent = (fisElem, direction) ->
	if fisElem
		id = fisElem.id
		actSlide = app.elems[id].actSlide
		slides = fisElem.querySelectorAll '.features-image-slider_content-block li'
		if direction == "next"
			if actSlide == slides.length
				loadSlide = 1
			else
				loadSlide = actSlide + 1
		else if direction == "prev"
			if actSlide == 1
				loadSlide = slides.length
			else
				loadSlide = actSlide - 1
		else if !isNaN(direction)
			loadSlide = parseInt(direction,10)
		else
			loadSlide = 0
		if loadSlide > 0
			app.elems[id].actSlide = loadSlide
			loadSlideElem = slides[loadSlide - 1]
			fisElem.querySelector('.features-image-slider_container_left_links_elem.active').classList.remove 'active'
			fisElem.querySelectorAll('.features-image-slider_container_left_links_elem')[loadSlide-1].classList.add 'active'
			newImg = document.createElement 'div'
			newImg.innerHTML = '<img src="' + loadSlideElem.querySelector('.features-image-slider_content-block_img img').src + '" alt="' + loadSlideElem.querySelector('.features-image-slider_content-block_img img').alt + '" data-copyright="' + loadSlideElem.querySelector('.features-image-slider_content-block_img img').dataset.copyright + '" />'
			newImg.classList.add 'new'
			fisElem.querySelector('.features-image-slider_container_right_img_bg').appendChild newImg
			imgHeightDiff = newImg.querySelector('img').offsetHeight - fisElem.querySelector('.features-image-slider_container_right_img_bg img').offsetHeight
			imgHeightDiff = if imgHeightDiff < 0 then imgHeightDiff * (-1) else imgHeightDiff
			if imgHeightDiff > 30
				fisElem.querySelector('.features-image-slider_container_right_img_bg').style.maxHeight = fisElem.querySelector('.features-image-slider_container_right_img_bg img').offsetHeight + 'px'

			newText = document.createElement 'div'
			newText.classList.add 'new'
			newText.innerHTML = loadSlideElem.querySelector('.features-image-slider_content-block_textblock').innerHTML
			fisElem.querySelector('.features-image-slider_container_right_content_txtbox').appendChild newText
			textHeightDiff = newText.offsetHeight - fisElem.querySelector('.features-image-slider_container_right_content_txtbox').offsetHeight
			textHeightDiff = if textHeightDiff < 0 then textHeightDiff * (-1) else textHeightDiff
			fisElem.querySelector('.features-image-slider_container_right_content_txtbox').style.maxHeight = (fisElem.querySelector('.features-image-slider_container_right_content_txtbox').offsetHeight - 24) + 'px'
			if textHeightDiff > 30
				fisElem.querySelector('.features-image-slider_container_right_content_txtbox').style.maxHeight = newText.offsetHeight + 'px'
			newText.classList.add 'added'

			if imgHeightDiff > 30
				fisElem.querySelector('.features-image-slider_container_right_img_bg').style.maxHeight = newImg.querySelector('img').offsetHeight + 'px'
			window.clearInterval app.elems[id].aniInterval
			app.elems[id].aniInterval = window.setInterval ->
				window.clearInterval app.elems[id].aniInterval
				fisElem = document.getElementById id
				fisElem.querySelector('.features-image-slider_container_right_img_bg').innerHTML = fisElem.querySelector('.features-image-slider_container_right_img_bg .new').innerHTML
				fisElem.querySelector('.features-image-slider_container_right_img_bg').style.maxHeight = ""
				fisElem.querySelector('.features-image-slider_container_right_img_bg').style.backgroundImage = 'url("' + fisElem.querySelector('.features-image-slider_container_right_img_bg img').src + '")'
				fisElem.querySelector('.features-image-slider_container_right_content_txtbox').innerHTML = fisElem.querySelector('.features-image-slider_container_right_content_txtbox .new').innerHTML
				fisElem.querySelector('.features-image-slider_container_right_content_txtbox').style.maxHeight = ""
				app.ImageCopyrightBinder()
				return
			, 300
	return

# Feature Video Slider
app.FeatureVideoSliderBinder = ->
	for fvsElem in document.querySelectorAll('.features-video-slider')
		app.SetId fvsElem, 'fvs_'
		app.elems[fvsElem.id] = {
			'aniInterval': undefined,
			'videoSizeInterval': undefined,
			'actSlide': 1
		}
		slides = fvsElem.querySelectorAll '.features-video-slider_content-block li'
		nextBtn = fvsElem.querySelector '.features-video-slider_container_block_buttons_right .btn'
		prevBtn = fvsElem.querySelector '.features-video-slider_container_block_buttons_left .btn'
		video = fvsElem.querySelector('.features-video-slider_container_video video')
		if slides.length > 1
			nextBtn.addEventListener 'click', (e) ->
				e.preventDefault()
				app.FeatureVideoSliderEvent this.closest('.features-video-slider'), 'next'
				return
			prevBtn.addEventListener 'click', (e) ->
				e.preventDefault()
				app.FeatureVideoSliderEvent this.closest('.features-video-slider'), 'prev'
				return
		else
			# uncomment following line to fully hide buttons row
			#fvsElem.querySelector('.features-video-slider_container_block_buttons').classList.add 'hide'
			nextBtn.classList.remove 'primary'
			nextBtn.disabled = true
			prevBtn.disabled = true

		app.FeatureVideoSliderBindVideoEvents video
	return
app.FeatureVideoSliderBindVideoEvents = (videoElem) ->
	videoBtn = videoElem.closest('.features-video-slider').querySelector '.video-button'
	slideVideoBtn = videoElem.closest('.features-video-slider').querySelector '.features-video-slider_container_block_content_links_button'
	videoElem.addEventListener 'pause', (e) ->
		if !this.seeking
			this.removeAttribute('controls')
			this.closest('.features-video-slider').classList.remove 'playing'
		return
	videoBtn.addEventListener 'click', (e) ->
			e.preventDefault()
			app.FeatureVideoSliderPlayVideo this.closest('.features-video-slider')
			return
	slideVideoBtn.addEventListener 'click', (e) ->
		e.preventDefault()
		app.FeatureVideoSliderPlayVideo this.closest('.features-video-slider')
		return
	return 
app.FeatureVideoSliderEvent = (fvsElem, direction) ->
	if fvsElem
		id = fvsElem.id
		actVideo = fvsElem.querySelector('.features-video-slider_container_video video')
		actSlide = app.elems[id].actSlide
		slides = fvsElem.querySelectorAll '.features-video-slider_content-block li'

		if direction == "next"
			if actSlide == slides.length
				loadSlide = 1
			else
				loadSlide = actSlide + 1
		else if direction == "prev"
			if actSlide == 1
				loadSlide = slides.length
			else
				loadSlide = actSlide - 1
		else if !isNaN(direction)
			loadSlide = parseInt(direction,10)
		else
			loadSlide = 0

		if loadSlide > 0
			# pause actual playing video
			actVideo.pause()
			# update actSlide value
			app.elems[id].actSlide = loadSlide

			loadSlideElem = slides[loadSlide - 1]

			newVideo = document.createElement 'video'
			newLoaderImg = loadSlideElem.querySelector('.features-video-slider_content-block_loaderimg')
			newVideo.innerHTML = loadSlideElem.querySelector('video').innerHTML
			newVideo.classList.add 'new'
			fvsElem.querySelector('.features-video-slider_container_video').appendChild newVideo
			if newLoaderImg
				newLoaderImgElem = document.createElement 'div'
				newLoaderImgElem.innerHTML = '<div class="features-video-slider_container_video_loaderimg" style="background-image: url(' + newLoaderImg.src + ');"></div>'
				newLoaderImgElem.querySelector('.features-video-slider_container_video_loaderimg').appendChild newLoaderImg.cloneNode()
				newLoaderImgElem.classList.add 'new'
				fvsElem.querySelector('.features-video-slider_container_video').appendChild newLoaderImgElem
			
			fvsElem.querySelector('.features-video-slider_container_video').style.maxHeight = fvsElem.querySelector('.features-video-slider_container_video').offsetHeight + 'px'
			window.clearInterval app.elems[id].videoSizeInterval
			app.elems[id].videoSizeInterval = window.setInterval ->
				window.clearInterval app.elems[id].videoSizeInterval
				videoHeightDiff = newVideo.offsetHeight - fvsElem.querySelector('.features-video-slider_container_video').offsetHeight
				videoHeightDiff = if videoHeightDiff < 0 then videoHeightDiff * (-1) else videoHeightDiff
				if videoHeightDiff > 30
					fvsElem.querySelector('.features-video-slider_container_video').style.maxHeight = newVideo.offsetHeight + 'px'
					fvsElem.querySelector('.features-video-slider_container_video').style.minHeight = newVideo.offsetHeight + 'px'
				fvsElem.querySelector('.features-video-slider_container_video').classList.add 'added'
				return
			, 50

			oldContentHeight = fvsElem.querySelector('.features-video-slider_container_block_content').offsetHeight
			newContent = document.createElement 'div'
			newContent.classList.add 'new'
			newContent.innerHTML = '<div class="features-video-slider_container_block_content_headline">' + loadSlideElem.querySelector('.features-video-slider_content-block_headline').innerHTML + '</div>' + (if loadSlideElem.querySelector('.features-video-slider_content-block_subline') then '<div class="features-video-slider_container_block_content_subline">' + loadSlideElem.querySelector('.features-video-slider_content-block_subline').innerHTML + '</div>' else '') + '<div class="features-video-slider_container_block_content_text">' + loadSlideElem.querySelector('.features-video-slider_content-block_text').innerHTML + '</div><div class="features-video-slider_container_block_content_links"><div class="features-video-slider_container_block_content_links_button">' + loadSlideElem.querySelector('.features-video-slider_content-block_button').innerHTML + '</div>' + (if loadSlideElem.querySelector('.features-video-slider_content-block_link') then '<div class="features-video-slider_container_block_content_links_link-container"><div class="features-video-slider_container_block_content_links_link-container_link">' + loadSlideElem.querySelector('.features-video-slider_content-block_link').innerHTML + '</div></div>' else '') + '</div>'
			fvsElem.querySelector('.features-video-slider_container_block_content').appendChild newContent
			contentHeightDiff = newContent.offsetHeight - oldContentHeight
			contentHeightDiff = if contentHeightDiff < 0 then contentHeightDiff * (-1) else contentHeightDiff
			fvsElem.querySelector('.features-video-slider_container_block_content').style.maxHeight = oldContentHeight + 'px'
			fvsElem.querySelector('.features-video-slider_container_block_content').style.maxHeight = newContent.offsetHeight + 'px'
			fvsElem.querySelector('.features-video-slider_container_block_content').style.minHeight = newContent.offsetHeight + 'px'
			newContent.classList.add 'added'
			
			
			
			window.clearInterval app.elems[id].aniInterval
			app.elems[id].aniInterval = window.setInterval ->
				window.clearInterval app.elems[id].aniInterval
				fvsElem = document.getElementById id
				fvsElem.querySelector('.features-video-slider_container_video').innerHTML = '<video preload="metadata">' + fvsElem.querySelector('.features-video-slider_container_video .new').innerHTML + '</video>' + (if fvsElem.querySelector('.features-video-slider_container_video .new .features-video-slider_container_video_loaderimg') then fvsElem.querySelector('.features-video-slider_container_video .new .features-video-slider_container_video_loaderimg').closest('.new').innerHTML else '') + '<div class="features-video-slider_container_video_play-button">' + fvsElem.querySelector('.features-video-slider_container_video .features-video-slider_container_video_play-button').innerHTML + '</div>'
				fvsElem.querySelector('.features-video-slider_container_video').classList.remove 'added'
				fvsElem.querySelector('.features-video-slider_container_video').style.maxHeight = ""
				window.clearInterval app.elems[id].videoSizeInterval
				app.elems[id].videoSizeInterval = window.setInterval ->
					window.clearInterval app.elems[id].videoSizeInterval
					fvsElem.querySelector('.features-video-slider_container_video').style.minHeight = ""
				, 50
				fvsElem.querySelector('.features-video-slider_container_block_content').innerHTML = fvsElem.querySelector('.features-video-slider_container_block_content .new').innerHTML
				fvsElem.querySelector('.features-video-slider_container_block_content').style.maxHeight = ""
				fvsElem.querySelector('.features-video-slider_container_block_content').style.minHeight = ""

				video = fvsElem.querySelector('.features-video-slider_container_video video')
				app.FeatureVideoSliderBindVideoEvents video
				return
			, 300
	return
app.FeatureVideoSliderPlayVideo = (fvsElem) ->
	if fvsElem
		video = fvsElem.querySelector('.features-video-slider_container_video video')
		video.setAttribute('controls',true)
		video.play()
		fvsElem.classList.add 'playing'
	return

# Header
app.HeaderBinder = ->
	headerElem = document.getElementById 'header'
	if headerElem
		headerElem.querySelector('#header_burger-button').addEventListener 'click', (e) ->
			e.preventDefault()
			this.closest('#header').classList.toggle('menu-open')
			return
		if app.HasClass headerElem, 'flyout'
			for navElem in headerElem.querySelectorAll('#header_menu a')
				navElem.addEventListener 'click', (e) ->
					if this.closest('li').dataset.directlink == "false" && window.innerWidth > 1024
						e.preventDefault()
						childCount = this.closest('li').dataset.child
						flyoutElem = this.closest('#header').querySelector('.header-flyout')
						for navElem in document.getElementById('header').querySelectorAll('#header_menu li.open')
							navElem.classList.remove 'open'
						if app.HasClass flyoutElem, 'active'
							if flyoutElem.querySelector('.header-flyout_entry.active')
								if flyoutElem.querySelector('.header-flyout_entry.active').dataset.child == childCount
									flyoutElem.classList.remove 'active'
									document.getElementById('header').classList.remove 'flyout-open'
								else
									if flyoutElem.querySelector('.header-flyout_entry.active')
										flyoutElem.querySelector('.header-flyout_entry.active').classList.remove 'active'
									for flyoutEntryElem in flyoutElem.querySelectorAll('.header-flyout_entry')
										if flyoutEntryElem.dataset.child == childCount
											flyoutEntryElem.classList.add 'active'
											this.closest('li').classList.add 'open'
											document.getElementById('header').classList.add 'flyout-open'
						else
							flyoutElem.style.paddingTop = document.getElementById('header').offsetHeight + 'px'
							flyoutElem.classList.add 'active'
							if flyoutElem.querySelector('.header-flyout_entry.active')
								flyoutElem.querySelector('.header-flyout_entry.active').classList.remove 'active'
							for flyoutEntryElem in flyoutElem.querySelectorAll('.header-flyout_entry')
								if flyoutEntryElem.dataset.child == childCount
									flyoutEntryElem.classList.add 'active'
									this.closest('li').classList.add 'open'
									document.getElementById('header').classList.add 'flyout-open'
							
					return
	return

# Offer-Header
app.OfferHeaderBinder = ->
	for ohElem in document.querySelectorAll('.offer-header')
		ohElem.querySelector('.offer-header_button_sh .btn').addEventListener 'click', (e) ->
			e.preventDefault()
			app.CopyText window.location
			return
	return

# Offer-Results
app.OfferResultsBinder = ->
	for orElem in document.querySelectorAll '.offer-results'
		app.OfferResultsShowOffers orElem
		# bind pagination or load more button
		if !app.HasClass orElem, 'use-pagination'
			orElem.querySelector('.offer-results_load-more_btn .btn').addEventListener 'click', (e) ->
				e.preventDefault()
				this.closest('.offer-results').dataset.shown = parseInt(this.closest('.offer-results').dataset.shown,10) + parseInt(this.closest('.offer-results').dataset.step,10)
				app.OfferResultsShowOffers this.closest('.offer-results')
				return			
		else
			orElem.querySelector('.offer-results_load-more').classList.add 'hide'
			if orElem.querySelectorAll('.offer-row').length < 1
				orElem.querySelector('.offer-results_pagination-control').classList.add 'hide'
			for paginationControlElem in orElem.querySelectorAll('.offer-results_pagination-control_prev, .offer-results_pagination-control_next')
				paginationControlElem.addEventListener 'click', (e) ->
					e.preventDefault()
					this.blur()
					orElem = this.closest('.offer-results')
					if orElem
						activePageIndex = parseInt orElem.querySelector('.offer-results_pagination-control nav a.active').innerHTML, 10
						if app.HasClass this, 'offer-results_pagination-control_next'
							if activePageIndex < app.offersPages
								pageIndex = activePageIndex + 1
							else
								pageIndex = -1
						else
							if activePageIndex > 1
								pageIndex = activePageIndex - 1
							else
								pageIndex = -1
						if pageIndex > 0
							orElem.querySelector('.offer-results_pagination-control nav li:nth-child(' + pageIndex + ') a').click()
					return
		# bind sort functionality
		sortControl = orElem.querySelector '.offer-results_sort .advanced-select'
		if sortControl
			for radioElem in sortControl.querySelectorAll 'input[type="radio"]'
				radioElem.addEventListener 'change', (e) ->
					sortParameters = e.target.value.split('_')
					app.OfferResultsDeletePagination()
					app.SortOfferRows sortParameters[0], sortParameters[1], orElem
					return
	return
app.OfferResultsUpdateRemainder = (targetElem, remainder) ->
	targetElem.classList.remove 'zero'
	targetElem.classList.remove 'singular'
	targetElem.classList.remove 'plural'
	loadMoreBtnElem = targetElem.closest('.offer-results').querySelector('.offer-results_load-more_btn .btn')
	loadMoreBtnElem.removeAttribute 'disabled'
	if app.hideLoadMore
		loadMoreBtnElem.closest('.offer-results_load-more').classList.remove 'hide'
	if remainder < 1
		targetElem.classList.add 'zero'
		loadMoreBtnElem.setAttribute 'disabled', true
		if app.hideLoadMore
			loadMoreBtnElem.closest('.offer-results_load-more').classList.add 'hide'
	else if remainder > 1
		targetElem.classList.add 'plural'
	else
		targetElem.classList.add 'singular'
	targetElem.querySelector('.count').innerHTML = remainder
	return
app.OfferResultsUpdatePagination = (orElem) ->
	if orElem
		paginationControlList = orElem.querySelector '.offer-results_pagination-control nav'
		if paginationControlList
			prevControlElem = orElem.querySelector '.offer-results_pagination-control_prev'
			nextControlElem = orElem.querySelector '.offer-results_pagination-control_next'
			prevControlElem.classList.add 'disabled'
			prevControlElem.setAttribute 'aria-disabled', 'true'
			nextControlElem.classList.add 'disabled'
			nextControlElem.setAttribute 'aria-disabled', 'true'
			if app.offersPages > 1
				nextControlElem.classList.remove 'disabled'
				nextControlElem.setAttribute 'aria-disabled', 'false'
			paginationControlList.innerHTML = ""
			for pageIndex in [1..app.offersPages]
				pageControlElem = document.createElement 'li'
				pageControlElem.innerHTML = '<a href="#" ' + (if pageIndex == 1 then 'class="active"' else '') + ' aria-label="Zur Seite ' + pageIndex + '" aria-current="' + (if pageIndex == 1 then 'true' else 'false') + '">' + pageIndex + '</a>'
				pageControlElem.querySelector('a').addEventListener 'click', (e) ->
					e.preventDefault()
					this.blur()
					this.closest('.offer-results_pagination-control').querySelector('a.active').setAttribute('aria-current','false')
					this.closest('.offer-results_pagination-control').querySelector('a.active').classList.remove 'active'
					this.classList.add 'active'
					this.setAttribute 'aria-current', 'true'
					app.OffersResultsPaginate this.closest('.offer-results'), this.innerHTML
					return
				paginationControlList.appendChild pageControlElem
			# load last pagination
			app.lastPageFilterRestore = false
			app.OfferResultsLoadPagination orElem 
	return
app.OffersResultsPaginate = (orElem, pageIndex) ->
	app.OfferResultsSavePagination pageIndex
	if orElem
		for offerElem in orElem.querySelectorAll '.offer-row'
			offerElem.classList.add 'pagination-hide'
			if offerElem.dataset.page == pageIndex
				offerElem.classList.remove 'pagination-hide'
		prevControlElem = orElem.querySelector '.offer-results_pagination-control_prev'
		nextControlElem = orElem.querySelector '.offer-results_pagination-control_next'
		if parseInt(pageIndex,10) == 1
			prevControlElem.classList.add 'disabled'
			prevControlElem.setAttribute 'aria-disabled', 'true'
		else
			prevControlElem.classList.remove 'disabled'
			prevControlElem.setAttribute 'aria-disabled', 'false'
		if parseInt(pageIndex,10) == app.offersPages
			nextControlElem.classList.add 'disabled'
			nextControlElem.setAttribute 'aria-disabled', 'true'
		else
			nextControlElem.classList.remove 'disabled'
			nextControlElem.setAttribute 'aria-disabled', 'false'
		app.ScrollToElem orElem, 0, false, true
	return
app.OfferResultsSavePagination = (pageIndex) ->
	app.lastPageIndex = parseInt(pageIndex,10)
	window.localStorage.setItem 'last-page-index', pageIndex
	return
app.OfferResultsLoadPagination = (orElem) ->
	if app.lastPageIndex
		orElem.querySelectorAll('.offer-results_pagination-control li')[app.lastPageIndex - 1].querySelector('a').click()
	return
app.OfferResultsDeletePagination = ->
	if !app.lastPageFilterRestore
		app.lastPageIndex = null
		window.localStorage.removeItem 'last-page-index'
	return
app.OfferResultsShowOffers = (targetElem) ->
	offerCount = 0
	offersPerPage = parseInt targetElem.dataset.step, 10
	offersPage = 0
	maxShow = if app.HasClass(targetElem, 'use-pagination') then parseInt(targetElem.dataset.step, 10) else parseInt(targetElem.dataset.shown, 10)
	for offerElem in targetElem.querySelectorAll('.offer-row')
		offerElem.classList.remove 'hide'
		if app.HasClass targetElem, 'use-pagination'
			offerElem.dataset.page = -1
			offerElem.classList.remove 'pagination-hide'
		if !app.HasClass offerElem, 'no-match'
			offerCount++
			if app.HasClass targetElem, 'use-pagination'
				if offerCount % offersPerPage == 1
					offersPage++
				offerElem.dataset.page = offersPage
			if offerCount > maxShow
				if app.HasClass targetElem, 'use-pagination'
					offerElem.classList.add 'pagination-hide'
				else
					offerElem.classList.add 'hide'

	if !app.HasClass targetElem, 'use-pagination'
		remainCount = offerCount - maxShow
		counterElem = targetElem.querySelector('.offer-results_load-more_count')
		app.OfferResultsUpdateRemainder counterElem, remainCount
	else
		app.offersPages = offersPage
		app.OfferResultsUpdatePagination targetElem

	return
app.AppendOfferResults = ->
	ocElem = document.querySelector '.offer-counts'
	orElem = document.querySelector '.offer-results'

	offerCount = 0
	for offer in app.offers
		# check if offer vacancy date is too old
		if !((offer.vacancyDate*1000) < new Date().getTime())
			# START VACANCY CHECK CONDITION
			app.AdvancedSelectIncrementSpan('area_'+offer.area)
			app.AdvancedSelectIncrementSpan('type_'+offer.type)
			app.AdvancedSelectIncrementSpan('classification_'+offer.classification)
			app.AdvancedSelectIncrementSpan('profession_'+offer.profession)
			app.AdvancedSelectIncrementSpan('temporary_'+offer.temporary)
			if offer.workingTime == 3
				app.AdvancedSelectIncrementSpan('workingtime_1')
				app.AdvancedSelectIncrementSpan('workingtime_2')
			else
				app.AdvancedSelectIncrementSpan('workingtime_'+offer.workingTime)
			if orElem
				app.AddOfferRowElem orElem.querySelector('.offer-results_list'), offer.id, offer.title, offer.employer, document.getElementsByName('area_'+offer.area)[0].textContent.replace(/[(][0-9]*[/]*[0-9]*[)]/g,''), offer.city, document.getElementsByName('type_'+offer.type)[0].innerText.replace(/[(][0-9]*[/]*[0-9]*[)]/g,''), offer.vacancy, offer.type, offer.profession, offer.classification, offer.area, offer.temporary, offer.workingTime, offer.vacancyDate, offer.onlineDate
				
				# increment offerCount
				offerCount++
			# END VACANCY CHECK CONDITION
	
	# show offer counts
	if ocElem
		app.UpdateOfferCounts ocElem, offerCount

	if orElem
		orElem.dataset.count = offerCount
	
	# sort offerRows by active value of the advanced-select for sort
	sortParameters = orElem.querySelector('.offer-results_sort .advanced-select input[type="radio"]:checked').value.split('_')
	if sortParameters.length == 2
		app.SortOfferRows sortParameters[0], sortParameters[1], orElem

	# check if a stored filter exists and use it
	if app.filter.active.length > 0
		app.lastPageFilterRestore = true
		app.FilterOfferRows app.filter.active

	return
app.UpdateASRSpansByOfferResults = ->
	for offer in document.querySelectorAll('.offer-results .offer-row')
		if !app.HasClass offer, 'no-match'
			app.AdvancedSelectIncrementSpan('area_'+offer.dataset.area)
			app.AdvancedSelectIncrementSpan('type_'+offer.dataset.type)
			app.AdvancedSelectIncrementSpan('classification_'+offer.dataset.classification)
			app.AdvancedSelectIncrementSpan('profession_'+offer.dataset.profession)
			app.AdvancedSelectIncrementSpan('temporary_'+offer.dataset.temporary)
			if offer.dataset.workingtime == 3
				app.AdvancedSelectIncrementSpan('workingtime_1')
				app.AdvancedSelectIncrementSpan('workingtime_2')
			else
				app.AdvancedSelectIncrementSpan('workingtime_'+offer.dataset.workingtime)
	return


# Search-Bar-Hero
app.SearchBarHeroBinder = ->
	searchBarHeroElem = document.querySelector('.search-bar-hero')
	if searchBarHeroElem
		document.getElementById('header').classList.add 'invert'
		document.getElementById('header').classList.add 'absolute'

# #########
# PAGES
# #########

# Distribution Page
app.DistributionPageBinder = ->
	if document.querySelector('.search-bar.big')
		if document.querySelector('.search-bar.big input').value == ""
			app.CheckForOffersUpdate('search-bar-big')
		else
			app.UpdateASRSpansByOfferResults()
	orElem = document.querySelector '.offer-results'
	asrElem = document.querySelector '.advanced-select-row'
	if orElem && asrElem
		for checkboxElem in asrElem.querySelectorAll('input[type="checkbox"]')
			checkboxElem.addEventListener 'change', (e) ->
				app.OfferResultsDeletePagination()
				filters = []
				orElem = document.querySelector '.offer-results'
				asrElem = this.closest('.advanced-select-row')
				for checkedCheckboxElem in asrElem.querySelectorAll('input[type="checkbox"]:checked')
					filterPair = checkedCheckboxElem.closest('.checkbox').getAttribute('name').split('_')
					filter = {'type': filterPair[0], 'value': filterPair[1]}
					filters.push filter
				orElem.dataset.shown = orElem.dataset.step
				app.FilterOfferRows filters
				app.AdvancedSelectResetSpans asrElem
				app.UpdateASRSpansByOfferResults()
				app.OfferResultsShowOffers orElem
				return
		for filterPair in app.filter.active
			for checkboxElem in asrElem.querySelectorAll('input[type="checkbox"]')
				if checkboxElem.getAttribute("name") == ('"' + filterPair.type + '_' + filterPair.value + '"')
					checkboxElem.setAttribute('checked', true)
					app.TriggerEvent checkboxElem, "change"

	app.OfferResultsBinder()
	return


# #########
# MAIN
# #########

app.Main = ->
	# ### COMMON
	app.GetHashedIcon()
	app.ImageCopyrightBinder()
	app.NavLinkBlurBinder()
	app.ScrollControlBinder()

	# ### ATOMS
	app.ButtonBinder()
	app.FilterTagBinder()
	app.MapBinder()
	app.SelectBinder()
	app.TagBinder()

	# ### MOLECULES
	app.AdvancedSelectBinder()
	app.CalendarEntriesBinder()
	app.CookieBannerBinder()
	app.HeaderFlyoutBinder()
	app.FilterBarsBinder()
	app.JobProfileCardBinder()
	app.ScrollDownHintBinder()
	app.SearchBarBinder()
	app.SearchFilterBinder()
	app.ToTopButtonBinder()

	# ### ORGANISMS
	app.AccordionBinder()
	app.AdvancedSelectRowBinder()
	app.CalenderOverviewBinder()
	app.CareerChoiceTestBinder()
	app.CctMapBinder()
	app.CctRangeSliderBinder()
	app.ContactBinder()
	app.FeatureImageSliderBinder()
	app.FeatureVideoSliderBinder()
	app.HeaderBinder()
	app.OfferHeaderBinder()
	app.SearchBarHeroBinder()

	# ### PAGES
	app.DistributionPageBinder()

	return

app.Main()